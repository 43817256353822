import React from 'react'
import PropTypes from 'prop-types'
import { capitalize } from '../../../../utils/helpers'

const ObjectComponentMounter = ({ dispatch: unsafeDispatch, kind, getTypeObject, ...props }) => {
  if (!kind) return null
  const object = getTypeObject(kind)
  if (!object) throw new Error('El tipo de objeto no existe (Form, Template)')
  if (!unsafeDispatch) throw new Error('No existe tipo de componente a montar (Form, Template, Answer)')
  const dispatch = capitalize(unsafeDispatch)
  const Component = object[dispatch]
  return <Component {...props} />
}

export default ObjectComponentMounter

ObjectComponentMounter.propTypes = {
  dispatch: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  getTypeObject: PropTypes.func.isRequired
}
