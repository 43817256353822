import React from 'react'
import CheckboxField from '../../../../molecules/fields/CheckboxField'
import FieldTitle from '../../../../molecules/FieldTitle'

const AleatoryOrder = () => {
  return (
    <>
      <FieldTitle title="Orden de preguntas y respuestas" className="md:mb-3" />
      <CheckboxField name="aleatory_order" margin="none" label="Presentar preguntas y respuestas de forma aleatoria." />
      <p className="ml-7 text-gray-500">
        Las preguntas y las respuestas se mostrarán de forma aleatoria cada vez que se llene el formulario.
        <br />
        Si estableces grupos de preguntas, estas se mantendrán en el mismo bloque.
      </p>
    </>
  )
}

export default AleatoryOrder

AleatoryOrder.propTypes = {}
