import PropTypes from 'prop-types'
import React from 'react'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioCitizenField from '../question_fields/RadioCitizenField'
import AssessmentRadioField from '../question_config_fields/AssessmentRadioField'

const Form = props => {
  return <AssessmentRadioField baseName={props.baseName} />
}

const Template = props => {
  if (props.question.editable_alternative) {
    return <AssessmentRadioField baseName={props.baseName} label={props.question.payload?.label} />
  } else {
    return <RadioCitizenField payload={props.question.payload} name={props.name} disabled />
  }
}

const AssessmentRadioQuestion = {
  selectOption: {
    value: 'assessment_radio',
    label: 'Botón (selección única)',
    icon: <RadioButtonCheckedIcon fontSize="small" />
  },
  Form,
  Template,
  name: 'AssessmentRadioQuestion'
}

export default AssessmentRadioQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  baseName: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
