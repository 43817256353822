import React, { useState } from 'react'
import Container from '../../../atoms/Container'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { useHistory } from 'react-router-dom'
import BaseFormCard from '../../../organisms/forms/BaseFormCard'
import SelectAndPreviewCards from '../../../organisms/forms/SelectAndPreviewCards'
import HeaderCreateForm from '../../../organisms/forms/HeaderCreateForm'
import axios from 'axios'
import { useQuery } from 'react-query'
import TemplatePreviewModal from '../../../organisms/forms/TemplatePreviewModal'
import useScrollToRef from '../../../../hooks/useScrollToRef'

const getPublishedTemplates = () => axios.get(`/admin/v1/templates/published.json`).then(res => res.data)

const FormsNewSetup = () => {
  const history = useHistory()
  const [showTemplateSelector, setShowTemplateSelector] = useState(false)
  const { data: templates, status } = useQuery(['publishedTemplates'], getPublishedTemplates, {
    refetchOnWindowFocus: false
  })
  const [id, setId] = useState(null)
  const { ref: selectTemplateRef, scrollToRef: scrollToSelectTemplate } = useScrollToRef()

  if (status !== 'success') return null
  const newForm = () => history.push('/formularios/crear')
  const handleNewEventForm = () => history.push('/formularios/crear?type=assessment')
  const useTemplate = () => {
    setShowTemplateSelector(true)
    scrollToSelectTemplate()
  }
  const cardContent = [
    {
      title: 'Crear un formulario desde cero',
      body: 'Empieza con un formulario en blanco y agrega todas las preguntas que requieras.',
      action: newForm
    }
  ]
  if (templates.length > 0) {
    cardContent.push({
      title: 'Usar una plantilla',
      body: 'Empieza con un formulario estandarizado y modifica las preguntas en las plantillas que lo permitan.',
      action: useTemplate
    })
  }
  cardContent.push({
    title: 'Crear un formulario de evaluación',
    body: 'Usa este formulario sugerido, añade las preguntas que necesites y define sus respuestas.',
    action: handleNewEventForm
  })

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <HeaderCreateForm
          headerParams={{
            activeStep: 0,
            hint: 'Selecciona una opción para empezar a crear tu formulario:'
          }}
        />
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 my-6 md:my-6 justify-center">
          {cardContent.map((card, key) => (
            <BaseFormCard key={key} title={card.title} body={card.body} action={card.action} />
          ))}
        </div>
        <div
          className="bg-neutral-100 py-6 md:py-10 px-4 md:px-6"
          hidden={!showTemplateSelector}
          ref={selectTemplateRef}
        >
          <div className="font-bold text-2xl">Selecciona tu plantilla</div>
          <SelectAndPreviewCards
            collection={templates}
            createPath="/formularios/crear/plantilla/"
            onPreview={({ id }) => setId(id)}
          />
          {id && <TemplatePreviewModal templateId={id} onClose={() => setId(null)} />}
        </div>
      </Container>
    </>
  )
}

export default FormsNewSetup
