import PropTypes from 'prop-types'
import React from 'react'
import { getConfig } from '../../../configs/formsConfig'
import useSearchParams from '../../../hooks/useSearchParams'
import Wizard from './Wizard'
import ObjectComponentMounter from './config_items/ObjectComponentMounter'
import { getFormTypeObject } from '../../../configs/formTypeConfig'

const FormsForm = ({
  config: customConfig = {},
  initialValues,
  mutateForm,
  buttonMessage = 'Guardar y continuar',
  noPristineToDisable = false,
  step = 0,
  decoratorsList = []
}) => {
  const searchParams = useSearchParams()
  const formType = initialValues.subtype || searchParams.get('type') || 'general'
  const config = getConfig(customConfig)

  const stepPages = [
    null,
    <ObjectComponentMounter
      kind={formType}
      config={config}
      dispatch="configuration"
      key={0}
      getTypeObject={getFormTypeObject}
    />,
    <ObjectComponentMounter
      kind={formType}
      config={config}
      dispatch="questions"
      key={1}
      getTypeObject={getFormTypeObject}
    />
  ]

  return (
    <>
      <Wizard
        stepPages={stepPages}
        buttonMessage={buttonMessage}
        mutateForm={mutateForm}
        initialValues={initialValues}
        step={step}
        verifyBeforeUnload
        noPristineToDisable={noPristineToDisable}
        decoratorsList={decoratorsList}
      />
    </>
  )
}
export default FormsForm

FormsForm.propTypes = {
  buttonMessage: PropTypes.string,
  config: PropTypes.object,
  initialValues: PropTypes.object,
  mutateForm: PropTypes.func.isRequired,
  noPristineToDisable: PropTypes.bool,
  step: PropTypes.number,
  decoratorsList: PropTypes.arrayOf(PropTypes.func)
}
