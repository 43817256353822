import PropTypes from 'prop-types'
import React from 'react'
import DropdownCitizenField from '../question_fields/DropdownCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin, onChange } = props
  return (
    <DropdownCitizenField
      index={index}
      {...question}
      name={name}
      disabled={disabled}
      margin={margin}
      onChange={onChange}
    />
  )
}

const AssessmentDropdownQuestion = {
  Citizen,
  name: 'AssessmentDropdownQuestion'
}

export default AssessmentDropdownQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  question: PropTypes.object
}
