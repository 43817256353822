import React from 'react'
import * as validations from '../../../../utils/validations'
import TextField from '../../../molecules/fields/TextField'

const SubscribedEmail = () => {
  return (
    <TextField
      name="subscribed_email"
      label="Dirección de correo para recibir respuestas (opcional)"
      hint="Todas las respuestas a este formulario llegarán al correo electrónico que ingreses."
      margin="none"
      placeholder="Ej.: nombre@micorreo.com"
      validate={validations.isEmail()}
    />
  )
}

export default SubscribedEmail

SubscribedEmail.propTypes = {}
