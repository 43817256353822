import PropTypes from 'prop-types'
import React from 'react'
import CheckboxesCitizenField from '../question_fields/CheckboxesCitizenField'

const Citizen = props => {
  const { index, name, question, disabled = false, margin } = props
  return <CheckboxesCitizenField index={index} {...question} name={name} disabled={disabled} margin={margin} />
}

const AssessmentCheckboxesQuestion = {
  Citizen,
  name: 'AssessmentCheckboxesQuestion'
}

export default AssessmentCheckboxesQuestion

Citizen.propTypes = {
  disabled: PropTypes.bool,
  index: PropTypes.number,
  margin: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object
}
