import React from 'react'
import HeaderCreateForm from '../../../organisms/forms/HeaderCreateForm'
import Container from '../../../atoms/Container'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import useForm from '../../../../hooks/useForm'
import queryString from 'query-string'
import FormsHeader from '../../../molecules/FormsHeader'
import { createFormHint, steps } from '../../../../configs/formsConfig'
import * as uuid from 'uuid'
import FormsForm from '../../../organisms/forms/FormsForm'
import { preventSubmitPrivacyPolicy } from '../../../../utils/decorators'

const FormQuestions = () => {
  const history = useHistory()
  const { search } = useLocation()
  const { id: formId } = useParams()
  const { getForm, updateForm, invalidateForm } = useForm(formId)
  const urlSearchParams = queryString.parse(search)
  const { data: form, status } = getForm()
  const stepsLabeled = steps.map(step => ({ label: step }))
  const fromTemplate = Boolean(form?.template_id)

  const handleUpdateForm = async ({ values, config }) => {
    try {
      updateForm(
        { values, config },
        {
          onSuccess: () => {
            invalidateForm()
            history.push(`/formularios/${formId}/vista-previa?action=${urlSearchParams.action}`)
          }
        }
      )
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  if (status !== 'success') return null

  let initialValues = form
  if (form.steps_attributes?.length === 0) {
    initialValues = { ...form, steps_attributes: [{ questions_attributes: [{ key: uuid.v4() }] }] }
  } else if (form.steps_attributes?.length === 1 && form.steps_attributes[0].questions_attributes.length === 0) {
    const initialStep = { ...form.steps_attributes[0], questions_attributes: [{ key: uuid.v4() }] }
    initialValues = { ...form, steps_attributes: [initialStep] }
  }

  return (
    <>
      <PageNavigation breadcrumbData={form} />
      <Container className="pt-12">
        {urlSearchParams.action === 'new' || urlSearchParams.action === 'template' ? (
          <HeaderCreateForm headerParams={{ activeStep: 2, hint: createFormHint[urlSearchParams.action] }} />
        ) : (
          <FormsHeader
            title={`Editando formulario: ${form.name}`}
            hint="Edita el formulario digital"
            description="Configura y agrega las preguntas de tu formulario."
            activeStep={stepsLabeled[2]}
            steps={stepsLabeled}
          />
        )}
        <FormsForm
          mutateForm={handleUpdateForm}
          initialValues={initialValues}
          config={form.config}
          noPristineToDisable={fromTemplate}
          step={2}
          decoratorsList={[preventSubmitPrivacyPolicy]}
        />
      </Container>
    </>
  )
}

export default FormQuestions
