import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import TitleQuestion from './TitleQuestion'
import SelectField from '../../molecules/fields/SelectField'
import * as validations from '../../../utils/validations'
import {
  requiredToLabel,
  canBeCopied,
  getFirstEmailQuestion,
  canBeDeleted,
  getIndexSubtype,
  questionOptions,
  questionTypesGroupInclude,
  requiredDefault,
  showConditionalQuestionsSwitch,
  showDisableFutureDateSwitch,
  showEditableAlternativesSwitch,
  showRequiredSwitch,
  showOneRowRequired,
  showOneMaxColRequired,
  showShortTextLimit,
  isAssessmentQuestion,
  isConfirmationQuestion,
  showManualRequestSwitch
} from '../../../configs/questionTypesAdminConfig'
import QuestionFields from '../question_config_fields/QuestionFields'
import SwitchField from '../../molecules/fields/SwitchField'
import EditableAlternativesSwitch from './EditableAlternativesSwitch'
import PreviewModal from './PreviewModal'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { PaperContainerErrorsHandler } from './PaperContainerErrorsHandler'
import CopyButton from './CopyButton'
import { Field, useFormState, useForm } from 'react-final-form'
import useFieldValue from '../../../hooks/useFieldValue'
import { FormTypeContext } from '../../../utils/contexts'
import formValidations from '../../../utils/formValidations'
import Error from '../../molecules/Error'
import Tooltip from '../../atoms/Tooltip'
import ShortTextAnswerType from '../../molecules/fields/ShortTextAnswerType'

const LAST_NAME_REGEX = /[^.]+(?=[^.]*$)/i
const getCurrentAlternativeId = name => LAST_NAME_REGEX.exec(name)[0]

const QuestionCardNew = ({
  baseName,
  index,
  fields,
  editableAlternatives,
  parentQuestionIndex,
  questionsPreviousSteps,
  stepIndex,
  sortQuestions
}) => {
  const stepsValue = useFieldValue('steps_attributes')
  const baseNameIndex = `${baseName}[${index}]`
  const { change, mutators } = useForm()
  const { values } = useFormState()
  const kind = useFieldValue(`${baseNameIndex}.kind`)
  const key = useFieldValue(`${baseNameIndex}.key`)
  const questionKey = useFieldValue('key_question')
  const typeForm = useContext(FormTypeContext)

  const firstEmailQuestion = getFirstEmailQuestion(values)
  const isFirstCitizenEmailQuestion = firstEmailQuestion?.key === key
  const firstEmailRequiredReason = formValidations.getFirstEmailRequiredReason(values)
  const isFirstCitizenEmailRequired = isFirstCitizenEmailQuestion && Boolean(firstEmailRequiredReason)

  useEffect(() => {
    if (requiredDefault(kind, typeForm)) {
      change(`${baseNameIndex}.required`, true)
    }
  }, [kind])

  return (
    <PaperContainerErrorsHandler>
      <div className="p-6 bg-white">
        <div>
          <div className="flex flex-col-reverse items-center md:justify-between md:flex-row mb-6">
            <TitleQuestion
              index={index}
              questions={fields.value}
              parentQuestionIndex={parentQuestionIndex}
              questionsPreviousSteps={questionsPreviousSteps}
              fields={fields}
              sortQuestions={sortQuestions}
              baseNameIndex={baseNameIndex}
            />
            {questionTypesGroupInclude('applicant', kind) && (
              <div className="py-2 px-3 bg-red-300 border-2 solid border-orange-700 text-xs leading-none text-orange-800 font-bold rounded-sm w-max mb-6 md:mb-0">
                Información del solicitante
              </div>
            )}
          </div>
          {parentQuestionIndex && (
            <Field
              component="input"
              className="hidden"
              name={`${baseNameIndex}.alternative_id`}
              initialValue={getCurrentAlternativeId(baseName)}
            />
          )}
          <SelectField
            size="full"
            label="Tipo de pregunta"
            emptyLabel="Selecciona una pregunta"
            name={`${baseNameIndex}.kind`}
            margin="dense"
            containerClassName="min-w-full md:w-max md:min-w-96"
            validate={validations.required()}
            disabled={requiredDefault(kind, typeForm)}
            options={questionOptions(stepsValue, key, baseName, parentQuestionIndex !== undefined, typeForm)}
            onChange={() => {
              const namesToClear = [
                `${baseNameIndex}.payload`,
                `${baseNameIndex}.children_attributes`,
                `${baseNameIndex}.has_sub_questions`,
                `${baseNameIndex}.required`,
                `${baseNameIndex}.has_hint`
              ]

              namesToClear.forEach(name => {
                change(name, null)
              })
            }}
          />
        </div>
        <hr className="my-6" />
        <div>
          <QuestionFields baseName={baseNameIndex} index={index} stepIndex={stepIndex} />
        </div>
      </div>
      <aside className="bg-neutral-100 px-6 py-4 flex justify-between flex-col md:flex-row">
        <div className="flex flex-col md:flex-row">
          {requiredDefault(kind, typeForm) && (
            <p>
              Esta pregunta será <strong>obligatoria</strong> para {requiredToLabel(kind)}.
            </p>
          )}
          {showRequiredSwitch(kind, typeForm) && (
            <SwitchField
              name={`${baseNameIndex}.required`}
              validate={isFirstCitizenEmailRequired && formValidations.validateFirstEmailRequired(kind)}
              parentClassName="flex mr-10 md:max-w-32 mb-4 md:mb-0 items-center"
              labelClassName="ml-2"
              label="Pregunta obligatoria"
            />
          )}
          {showConditionalQuestionsSwitch(kind, parentQuestionIndex) && (
            <SwitchField
              name={`${baseNameIndex}.has_sub_questions`}
              parentClassName="flex mr-10 md:max-w-52 mb-4 md:mb-0 items-center"
              labelClassName="ml-2"
              label="Agregar preguntas para cada alternativa"
            />
          )}

          {showManualRequestSwitch(kind, typeForm) && (
            <SwitchField
              name={`${baseNameIndex}.manual_request`}
              parentClassName="flex mr-10 md:max-w-52 mb-4 md:mb-0 items-center"
              labelClassName="ml-2"
              label="Pregunta para registro manual de solicitud"
            />
          )}

          {showDisableFutureDateSwitch(kind) && (
            <SwitchField
              name={`${baseNameIndex}.disable_future_date`}
              parentClassName="mr-10"
              label={
                <span>
                  No permitir fechas
                  <br /> futuras
                </span>
              }
            />
          )}
          {showEditableAlternativesSwitch(kind, editableAlternatives) && (
            <EditableAlternativesSwitch baseName={baseNameIndex} />
          )}
          {showOneRowRequired(kind) && (
            <SwitchField
              name={`${baseNameIndex}.required_one_row`}
              parentClassName="flex mr-10 md:max-w-56 mb-4 md:mb-0 items-center"
              labelClassName="ml-2"
              label="Requiere una respuesta por cada fila"
            />
          )}
          {showOneMaxColRequired(kind) && (
            <SwitchField
              name={`${baseNameIndex}.max_one_col`}
              parentClassName="flex mr-10 md:max-w-64 mb-4 md:mb-0 items-center"
              labelClassName="ml-2"
              label="Limitar a una respuesta por columna"
            />
          )}
          {isAssessmentQuestion(kind) && (
            <SwitchField
              name={`${baseNameIndex}.auto_feedback`}
              parentClassName="flex mr-10 md:max-w-64 mb-4 md:mb-0 items-center"
              labelClassName="ml-2"
              label="Dar feedback automático"
            />
          )}
          {isConfirmationQuestion(kind, typeForm) && (
            <SwitchField
              name={`${baseNameIndex}.disable_confirmation`}
              parentClassName="flex mr-10 md:max-w-64 mb-4 md:mb-0 items-center"
              labelClassName="ml-2"
              label="No validar medio de contacto"
            />
          )}
          {showShortTextLimit(kind) && <ShortTextAnswerType name={baseNameIndex} />}
        </div>
        <div className="flex justify-between items-center mt-6 md:mt-0">
          <PreviewModal
            index={getIndexSubtype({ questionsPreviousSteps, questions: fields.value, index })}
            question={fields.value?.[index]}
          />
          {canBeCopied(kind, typeForm) && <CopyButton fields={fields} index={index} />}
          {canBeDeleted(kind, typeForm) && (
            <Tooltip title="Eliminar pregunta">
              <IconButton
                type="button"
                color="primary"
                onClick={() => {
                  if (key === questionKey) mutators.clear('key_question')
                  if (isFirstCitizenEmailRequired)
                    return alert(
                      `No puedes eliminar la pregunta correo porque se ha seleccionado la opción de enviar ${firstEmailRequiredReason} a los usuarios.`
                    )
                  fields.markForDestroy(index)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </aside>
      <Error name={`${baseNameIndex}.required`} className="px-4 pb-4" />
    </PaperContainerErrorsHandler>
  )
}
export default QuestionCardNew

QuestionCardNew.propTypes = {
  baseName: PropTypes.string,
  editableAlternatives: PropTypes.bool,
  fields: PropTypes.object,
  index: PropTypes.number,
  parentQuestionIndex: PropTypes.number,
  questionsPreviousSteps: PropTypes.array,
  stepIndex: PropTypes.number,
  sortQuestions: PropTypes.bool
}
