import React from 'react'
import PropTypes from 'prop-types'
import FieldTitle from '../../../molecules/FieldTitle'
import { fieldTitle } from '../../../../configs/templatesConfig'
import Value from '../../../molecules/Value'
import InfoAutoResponseFromTemplate from '../InfoAutoResponseFromTemplate'
import { HtmlField } from '../../../molecules/fields/HtmlField'
import * as validations from '../../../../utils/validations'
import useFieldValue from '../../../../hooks/useFieldValue'

const AutoResponse = ({ config }) => {
  const templateId = useFieldValue('template_id')
  const showInfoAutoResponseFromTemplate = config.auto_response === 'official_decide' && templateId
  return (
    <>
      <FieldTitle
        title={fieldTitle('auto_response', config.auto_response).title}
        hint={fieldTitle('auto_response', config.auto_response).hint}
      />
      {showInfoAutoResponseFromTemplate && <InfoAutoResponseFromTemplate />}
      <Value name="auto_response">
        {({ value: autoResponse }) =>
          autoResponse && (
            <HtmlField
              name="auto_response_text"
              label="Texto de la respuesta"
              hasMaxContentLength={false}
              allowScrolling
              className="ml-7"
              editorClassName="sm:max-w-xl"
              validate={validations.required()}
            />
          )
        }
      </Value>
    </>
  )
}

export default AutoResponse

AutoResponse.propTypes = {
  config: PropTypes.object
}
