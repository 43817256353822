import React from 'react'
import PropTypes from 'prop-types'
import FieldTitle from '../../../molecules/FieldTitle'
import { fieldTitle } from '../../../../configs/templatesConfig'
import clsx from 'clsx'
import PaymentMethodConfigurationSection from '../PaymentMethodConfigurationSection'

const Payment = ({ config }) => {
  const hasPaymentMethodConfig = config.enabled_payment_methods?.has_transfer_accounts
  return (
    <div className={clsx('flex flex-col', !hasPaymentMethodConfig && 'md:flex-row')}>
      <div>
        <FieldTitle
          title={fieldTitle('it_costs', config.it_costs).title}
          hint={fieldTitle('it_costs', config.it_costs).hint}
          disabled={!hasPaymentMethodConfig}
        />
      </div>
      <PaymentMethodConfigurationSection hasPaymentMethodConfig={hasPaymentMethodConfig} config={config} />
    </div>
  )
}

export default Payment

Payment.propTypes = {
  config: PropTypes.object
}
