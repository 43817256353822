import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import TextField from '../../molecules/fields/TextField'
import * as validations from '../../../utils/validations'
import { FormControlLabel, IconButton, Radio } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { FieldArray, useFieldArray } from 'react-final-form-arrays'
import Error from '../../molecules/Error'
import * as uuid from 'uuid'
import { Field, useForm } from 'react-final-form'
import useAutoScroll from '../../../hooks/useAutoScroll'
import useFieldValue from '../../../hooks/useFieldValue'
import LazyLoad from 'react-lazy-load'
import { Button } from '../../atoms/Button'
import useToggle from '../../../hooks/useToggle'
import CheckboxField from '../../molecules/fields/CheckboxField'

const AssessmentAlternativesField = ({
  baseName,
  alternativeValidations,
  asideAlternative,
  hasAddOtherOption,
  alternativeType = 'radio'
}) => {
  const { mutators } = useForm()
  const fieldName = `${baseName}.payload.alternatives`
  const alternatives = useFieldValue(fieldName)
  const scoreType = useFieldValue(`${baseName}.payload.score_type`)

  const { fields: fieldAlternatives } = useFieldArray(fieldName, { validate: alternativeValidations })
  const containerRef = useAutoScroll(alternatives.length)
  const showHr = alternatives.length > 5
  const initialOtherValue = typeof alternatives === 'object' && alternatives?.find(el => el.type === 'other')
  const [hasOtherValue, toggleHasOtherValue] = useToggle(initialOtherValue)

  const addOption = () => {
    if (hasOtherValue) {
      fieldAlternatives.insert(alternatives.length - 1, { type: 'default' })
    } else {
      fieldAlternatives.push({ type: 'default' })
    }
  }

  const addOtherOption = () => {
    fieldAlternatives.push({ type: 'other', label: 'Otro' })
    toggleHasOtherValue()
  }

  const deleteOption = (fields, index, fieldWithOther) => {
    fields.remove(index)
    mutators.clear(`${baseName}.children_attributes.${fields?.value[index]?.id}`)
    if (fieldWithOther) {
      toggleHasOtherValue()
    }
  }

  const handleCorrectAnswerRadioChange = (fields, index) => {
    fields.forEach((_, i) => {
      fields.update(i, {
        ...fields.value[i],
        correct_answer: i === index ? 'true' : 'false'
      })
    })
  }

  useEffect(() => {
    if (scoreType !== 'no_score' && fieldAlternatives.length > 0) {
      const lastAlternative = fieldAlternatives?.value[alternatives.length - 1]
      if (lastAlternative?.type === 'other') {
        deleteOption(fieldAlternatives, alternatives.length - 1, true)
      }
    }
  }, [scoreType])

  return (
    <>
      <FieldArray name={fieldName} validate={alternativeValidations}>
        {({ fields }) => (
          <div className="js-field-container">
            <fieldset>
              {asideAlternative}
              {showHr && <hr className="h-0.5 bg-neutral-400 my-4" />}
              <div className="overflow-y-auto max-h-80" ref={containerRef}>
                {fields.map((name, index) => {
                  const initialValue = fields?.value[index]?.id || uuid.v4()
                  const fieldWithOther = fields?.value[index]?.type === 'other'
                  const checked = fields?.value[index].correct_answer === 'true'
                  return (
                    <div className="min-h-12" key={initialValue}>
                      <LazyLoad>
                        <div className="flex gap-4 mb-4 w-full items-start">
                          <div className="flex flex-col items-end flex-grow max-w-104 min-h-23 relative">
                            <TextField
                              ariaLabel={`Alternativa ${index + 1}`}
                              placeholder={`Alternativa ${index + 1}`}
                              name={`${name}.label`}
                              margin="minimun"
                              size="4xl"
                              rootClassName="w-full"
                              validate={validations.mix(
                                validations.required(),
                                validations.uniqueAlternativeLabel(fieldName)
                              )}
                              disabled={fieldWithOther}
                            />
                            <Field
                              component="input"
                              className="hidden"
                              name={`${name}.id`}
                              initialValue={initialValue}
                            />
                            <Button
                              onClick={() => deleteOption(fields, index, fieldWithOther)}
                              variant="text"
                              className="p-0 md:ml-1 right-0 bottom-3 absolute"
                              fullWidth={false}
                            >
                              <DeleteIcon />
                              Eliminar
                            </Button>
                          </div>
                          <>
                            {scoreType === 'unique_score' && (
                              <div className="h-12 flex">
                                {alternativeType === 'radio' && (
                                  <FormControlLabel
                                    value={index}
                                    control={
                                      <Radio
                                        className="py-1"
                                        checked={checked}
                                        name={`${name}.correct_answer`}
                                        onChange={e => handleCorrectAnswerRadioChange(fields, index)}
                                      />
                                    }
                                    classes={{ label: 'text-base py-1' }}
                                    label="Respuesta correcta"
                                  />
                                )}
                                {alternativeType === 'checkbox' && (
                                  <CheckboxField
                                    name={`${name}.correct_answer`}
                                    label="Respuesta correcta"
                                    margin="none"
                                  />
                                )}
                              </div>
                            )}
                            {scoreType === 'varied_score' && (
                              <TextField
                                ariaLabel={`Indica el puntaje de alternativa ${index + 1}`}
                                placeholder={`Indica el puntaje. Ej.: 5`}
                                name={`${name}.score`}
                                margin="minimun"
                                size="md"
                                rootClassName="flex-1"
                                validate={validations.required()}
                                disabled={fieldWithOther}
                                type="number"
                              />
                            )}
                          </>
                        </div>
                      </LazyLoad>
                    </div>
                  )
                })}
              </div>
              {showHr && <hr className="h-0.5 bg-neutral-400 mt-3 mb-4" />}
            </fieldset>
            {alternatives.length < 5 && (
              <div className="flex items-center">
                <IconButton
                  type="button"
                  color="primary"
                  onClick={addOption}
                  classes={{ root: '-ml-4' }}
                  aria-label="Agregar alternativa"
                >
                  <AddBoxIcon style={{ fontSize: 50 }} />
                </IconButton>
                <p className="flex flex-col items-start md:items-center md:flex-row">
                  Añade una nueva opción
                  {scoreType === 'no_score' && hasAddOtherOption && !hasOtherValue && (
                    <>
                      {' o'}
                      <Button onClick={addOtherOption} variant="text" className="p-0 md:ml-1" fullWidth={false}>
                        añadir opción &quot;Otro&quot;
                      </Button>
                    </>
                  )}
                </p>
              </div>
            )}
            <div className="relative">
              <Error name={fields.name} />
            </div>
          </div>
        )}
      </FieldArray>
    </>
  )
}

export default AssessmentAlternativesField

AssessmentAlternativesField.propTypes = {
  alternativeValidations: PropTypes.func,
  asideAlternative: PropTypes.element,
  baseName: PropTypes.string,
  alternativeType: PropTypes.oneOf(['radio', 'checkbox']),
  hasAddOtherOption: PropTypes.bool
}
