import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FormSection from '../../molecules/FormSection'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import clsx from 'clsx'

const ConfigurationContainer = ({ title, configItems, bottomSpace }) => {
  return (
    <FormSection title={title} bottomSpace={bottomSpace}>
      {configItems.map((item, key) => {
        return (
          <Fragment key={key}>
            <div className={clsx(item.withoutSeparator && 'mb-3')}>{item.component}</div>
            {configItems.length - 1 !== key && !item.withoutSeparator && <FieldsSeparator />}
          </Fragment>
        )
      })}
    </FormSection>
  )
}

export default ConfigurationContainer

ConfigurationContainer.propTypes = {
  title: PropTypes.string,
  configItems: PropTypes.array,
  bottomSpace: PropTypes.bool
}
