import React from 'react'
import PropTypes from 'prop-types'
import SubType from '../../config_items/SubType'
import CoverImage from '../../config_items/CoverImage'
import SwornDeclaration from '../../config_items/SwornDeclaration'
import FormName from '../../config_items/FormName'
import ConfigurationContainer from '../../ConfigurationContainer'
import { getVisibleConfigItems } from '../../../../../configs/formTypeConfig'
import PublishSchedule from '../../config_items/PublishSchedule'
import { fieldTitle } from '../../../../../configs/templatesConfig'
import FieldTitle from '../../../../molecules/FieldTitle'
import HasWebhook from '../../config_items/HasWebhook'
import AutoResponse from '../../config_items/AutoResponse'
import FormHint from '../../config_items/FormHint'
import SubscribedEmail from '../../config_items/SubscribedEmail'
import Payment from '../../config_items/Payment'

const Configuration = ({ config }) => {
  const configItems = [
    {
      visible: true,
      component: <SubType config={config} />
    },
    {
      visible: true,
      component: (
        <FormName
          disabled={config.from_template}
          label="Nombre del trámite"
          hint={!config.from_template && 'Es el nombre que tendrá el formulario.'}
        />
      )
    },
    {
      visible: true,
      component: <CoverImage />
    },
    {
      visible: true,
      component: (
        <FormHint
          label="Indicación general del formulario"
          placeholder="Ej.: La recepción de documentos es de 8:00 a. m. a 6:00 p. m. Ingresa tu solicitud y un especialista se comunicará contigo."
          hint="Es la información que se le dará al ciudadano para ayudarlo con el proceso del trámite."
        />
      )
    },
    {
      visible: config.answers_by_email,
      component: <SubscribedEmail />
    },
    {
      visible: fieldTitle('it_costs', config.it_costs),
      component: <Payment config={config} />
    },
    {
      visible: fieldTitle('auto_response', config.auto_response),
      component: <AutoResponse config={config} />
    },
    {
      visible: true,
      component: <HasWebhook />
    },
    {
      visible: fieldTitle('is_trackable', config.is_trackable),
      component: (
        <FieldTitle
          title={fieldTitle('is_trackable', config.is_trackable)?.title}
          hint={fieldTitle('is_trackable', config.is_trackable)?.hint}
        />
      )
    },
    {
      visible: fieldTitle('sworn_declaration', config.sworn_declaration),
      component: <SwornDeclaration config={config} />
    },
    {
      visible: true,
      component: <PublishSchedule name="subscribed_email" />
    }
  ]

  const visibleConfigItems = getVisibleConfigItems(configItems)

  return <ConfigurationContainer configItems={visibleConfigItems} title="Información y configuración general" />
}

export default Configuration

Configuration.propTypes = {
  config: PropTypes.object
}
