import AssessmentForm from '../components/organisms/forms/form_types/AssessmentForm'
import GeneralForm from '../components/organisms/forms/form_types/GeneralForm'

const availableFormTypes = {
  general: GeneralForm,
  procedure: GeneralForm,
  event: GeneralForm,
  survey: GeneralForm,
  other: GeneralForm,
  assessment: AssessmentForm
}

const getFormTypeObject = kind => availableFormTypes[kind]

const getVisibleConfigItems = configItems => configItems.filter(item => item.visible)

export { getFormTypeObject, getVisibleConfigItems }
