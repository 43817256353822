import PropTypes from 'prop-types'
import React from 'react'
import DropdownCitizenField from '../question_fields/DropdownCitizenField'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import AssessmentDropdownField from '../question_config_fields/AssessmentDropdownField'

const Form = props => {
  return <AssessmentDropdownField baseName={props.baseName} />
}

const Template = props => {
  if (props.question.editable_alternative) {
    return <AssessmentDropdownField baseName={props.baseName} label={props.question.payload?.label} />
  } else {
    return <DropdownCitizenField payload={props.question.payload} name={props.name} disabled />
  }
}

const AssessmentDropdownQuestion = {
  selectOption: {
    value: 'assessment_dropdown',
    label: 'Desplegable (dropdown)',
    icon: <ArrowDropDownIcon fontSize="small" />
  },
  Form,
  Template,
  name: 'AssessmentDropdownQuestion'
}

export default AssessmentDropdownQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  baseName: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
