import React, { Fragment } from 'react'
import { matchPath } from 'react-router-dom'
import { parse } from 'date-fns'
import difference from 'lodash.difference'
import i18n from 'i18next'

export const numberFormatter = {
  currency: (amount, currencyText) => {
    const formatter = new Intl.NumberFormat('es-PE', {
      style: 'currency',
      currency: 'PEN'
    })
    if (['es', 'es-PE'].includes(i18n.language)) {
      return formatter.format(amount)
    } else {
      const formattedParts = formatter.formatToParts(amount)
      formattedParts.shift()
      formattedParts.shift()
      formattedParts.push({ type: 'literal', value: ' ' })
      formattedParts.push({ type: 'currency', value: currencyText })
      return formattedParts.map(part => part.value).join('')
    }
  },
  quantity: number => {
    const formatter = new Intl.NumberFormat('es-PE').format(number)
    return formatter
  }
}

export const parseToXB = (bytes, prefix) => {
  const prefixParse = { KB: 1, MB: 2, GB: 3 }
  return (bytes / Math.pow(1024, prefixParse[prefix])).toFixed(2) * 1
}

export const getAxiosErrorMessages = error => {
  const errorMessages = {}
  const errors = error.response.data
  for (const field in errors) {
    errorMessages[field] = errors[field][0]
  }
  return errorMessages
}

export const isEllipsisActive = element => {
  return element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
}

export const dataToOptions = (data, classNameFn) =>
  data?.map(option => ({
    value: option.code || option.id.toString(),
    label: option.name,
    className: classNameFn && classNameFn(option)
  })) || []

export const getUnselected = (data, selected, currentIndex) =>
  data.filter(row => !selected.includes(row.id.toString()) || row.id.toString() === selected[currentIndex])

export const objectToOptions = data =>
  Object.keys(data).map(key => ({
    value: key,
    label: data[key].name
  }))

export const dateTimeFormat = new Intl.DateTimeFormat('es', { hour: 'numeric', minute: 'numeric' })

export const getDateFromTime = time => {
  const date = new Date()
  const [hour, min] = time.split(':')
  date.setHours(hour, min, 0, 0)
  return date
}

export const urlToJson = url => `${url}.json`

export const hasExactURL = pathname => {
  const matches = ['/centro-de-ayuda/articulo-tutorial/:id', '/centro-de-ayuda/articulo-actualizacion/:id']
  const notMatches = ['/centro-de-ayuda/articulo-tutorial/crear', '/centro-de-ayuda/articulo-actualizacion/crear']
  return (
    !notMatches.includes(pathname) &&
    matches.find(
      match =>
        matchPath(pathname, {
          path: match,
          exact: true,
          strict: true
        }) !== null
    )
  )
}

export const addYAtTheEnd = values => {
  return values
    .slice(0, values.length - 2)
    .join(', ')
    .concat(values.length < 3 ? '' : ', ', values.slice(-2).join(' y '))
}

export const addYAtTheEndComponent = (values, component) => {
  const Component = component
  const listFormatted = values.reduce((prev, current, index) => {
    prev.push(<Component {...current.props}>{current.text}</Component>)
    if (index + 1 < values.length) {
      if (index === values.length - 2) {
        prev.push(' y ')
      } else {
        prev.push(', ')
      }
    }
    return prev
  }, [])
  return listFormatted.map((el, index) => <Fragment key={index}>{el}</Fragment>)
}

export const flatAnswers = answers => {
  const flattedAnswers = []
  answers.forEach(answer => {
    flattedAnswers.push({ ...answer })
    if (answer.sub_answers_attributes) {
      const subAnswer = Object.values(answer.sub_answers_attributes)[0]
      if (subAnswer) flattedAnswers.push(subAnswer)
    }
  })
  return flattedAnswers.flat()
}

export const compareByCompleteDate = (a, b) => {
  const dateA = parse(a.complete_date, 'dd/MM/yyyy HH:mm', new Date())
  const dateB = parse(b.complete_date, 'dd/MM/yyyy HH:mm', new Date())
  return dateA - dateB
}

export const secondsToMinutes = seconds => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  return `${minutes}:${formattedSeconds}`
}

export const findArrayDifference = (array1, array2) => {
  const difference1 = difference(array1, array2)
  const difference2 = difference(array2, array1)
  return difference1.length > 0 ? difference1 : difference2
}

export const parsedDate = dateValue => parse(dateValue, 'dd/MM/yyyy', new Date())
export const parsedDateEnd = dateValue => parse(`${dateValue} 23:59:59`, 'dd/MM/yyyy HH:mm:ss', new Date())

export const dateStringToObject = value => {
  const parsedDate = parse(value, 'dd/MM/yyyy', new Date())
  const objectDate = value
    ? { year: parsedDate.getFullYear(), month: parsedDate.getMonth() + 1, day: parsedDate.getDate() }
    : null
  return objectDate
}

export const convertCamelToSnake = str => {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toLowerCase()
}

export const parseDayMonthYear = str => {
  const [day, month, year] = str.split('/').map(Number)
  return new Date(year, month - 1, day)
}

const monthMap = {
  Enero: 0,
  Febrero: 1,
  Marzo: 2,
  Abril: 3,
  Mayo: 4,
  Junio: 5,
  Julio: 6,
  Agosto: 7,
  Septiembre: 8,
  Octubre: 9,
  Noviembre: 10,
  Diciembre: 11
}

const parseStatDate = str => {
  if (str.includes('/')) {
    return parseDayMonthYear(str)
  } else if (str.includes(' ')) {
    const [month, year] = str.split(' ')
    return new Date(year, monthMap[month])
  } else {
    return new Date(str)
  }
}

export const sortStatDates = list => {
  list.sort((a, b) => parseStatDate(a) - parseStatDate(b))
}

export const hourFormattedByNumber = hour => `${String(hour).padStart(2, '0')}:00`

export const capitalize = text => text[0].toUpperCase() + text.slice(1).toLowerCase()
