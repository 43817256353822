import React from 'react'
import CheckboxField from '../../../../molecules/fields/CheckboxField'

const AutoScore = () => {
  return (
    <>
      <CheckboxField name="auto_score" margin="none" label="Mostrar calificación automáticamente​." />
      <p className="ml-7 text-gray-500">Al finalizar la evaluación, las personas verán su resultado inmediatamente.</p>
    </>
  )
}

export default AutoScore

AutoScore.propTypes = {}
