import React from 'react'
import CheckboxField from '../../../molecules/fields/CheckboxField'
import FieldTitle from '../../../molecules/FieldTitle'
import { TagBox } from '../../../molecules/TagBox'
import Value from '../../../molecules/Value'
import TextField from '../../../molecules/fields/TextField'
import * as validations from '../../../../utils/validations'

const HasWebhook = () => {
  return (
    <>
      <FieldTitle
        title={
          <>
            Respuestas hacia una API <TagBox className="ml-2" label="BETA" />
          </>
        }
        hint={
          <CheckboxField
            name="has_webhook"
            margin="none"
            label="Quiero que las respuestas de los ciudadanos se registren también en un API de mi institución."
          />
        }
      />
      <Value name="has_webhook">
        {({ value }) =>
          value && (
            <>
              <TextField
                name="webhook_url"
                size="5xl"
                rootClassName="ml-8"
                rows={6}
                margin="high"
                label="Ingresa la ruta"
                hint="Haremos un POST con content-type: application/json"
                validate={validations.mix(validations.required(), validations.isURL())}
              />
              <TextField
                name="webhook_auth"
                size="full"
                rootClassName="ml-8"
                rows={6}
                margin="none"
                hint="Lo enviaremos en el header authorization y así podrás comprobar que el envío viene desde Facilita"
                label="Ingresa el texto de autenticación"
                validate={validations.required()}
              />
            </>
          )
        }
      </Value>
    </>
  )
}

export default HasWebhook

HasWebhook.propTypes = {}
