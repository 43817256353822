import PropTypes from 'prop-types'
import React from 'react'
import { useField } from 'react-final-form'
import clsx from 'clsx'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { marginSizes } from '../../../../configs/sizesConfig'
import Error from '../../../molecules/Error'

const ScoreType = ({ name, validate, options = [], disabled = false, ...props }) => {
  const { input } = useField(name, { validate, ...props })

  return (
    <div className="relative mt-8">
      <fieldset className={clsx('js-field-container', marginSizes.normal)}>
        <legend className="font-bold mb-2">Puntaje de la pregunta</legend>
        <RadioGroup
          aria-label="Puntaje de la pregunta"
          name={name}
          {...input}
          onChange={e => {
            input.onChange(e.target.value)
          }}
        >
          {options.map((o, index) => {
            const checked = o.value.toString() === input.value
            return (
              <div className="flex items-start flex-col" key={index}>
                <FormControlLabel
                  value={o.value.toString()}
                  control={<Radio disabled={disabled} className="py-1" checked={checked} />}
                  classes={{ label: 'text-base py-1' }}
                  label={o.label}
                />
                {checked && o.additionalInformation}
              </div>
            )
          })}
        </RadioGroup>
        <Error name={name} />
      </fieldset>
    </div>
  )
}

export default ScoreType

ScoreType.propTypes = {
  name: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  options: PropTypes.array,
  disabled: PropTypes.bool
}
