import React, { useMemo } from 'react'
import axios from 'axios'
import FormsForm from '../../../organisms/forms/FormsForm'
import HeaderCreateForm from '../../../organisms/forms/HeaderCreateForm'
import Container from '../../../atoms/Container'
import { useHistory } from 'react-router-dom'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import useInstitution from '../../../../hooks/useInstitution'
import useSearchParams from '../../../../hooks/useSearchParams'

const createForm = ({ values, config }) => axios.post('/admin/v1/forms.json', values, config)

const FormsNew = () => {
  const history = useHistory()
  const { getInstitution } = useInstitution()
  const { data: institution, status } = getInstitution()
  const searchParams = useSearchParams()
  const acceptedCivilRegistrationConditions = searchParams.get('accepted')
  const approvedTupa = searchParams.get('approvedTupa')
  const initialType = searchParams.get('type')

  const handleFormCreate = async ({ values, config }) => {
    try {
      const { data } = await createForm({ values, config })
      history.push(`/formularios/${data.id}/preguntas?action=new`)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  const initialValues = useMemo(
    () => ({
      steps_attributes: [
        {
          questions_attributes: []
        }
      ],
      civil_registration_conditions: {
        accepted: acceptedCivilRegistrationConditions,
        approvedTupa
      },
      subtype: initialType || undefined
    }),
    []
  )
  if (status !== 'success') return null
  const config = {
    enabled_payment_methods: institution.enabled_payment_methods,
    enabled_payment_transfer_accounts_attributes: institution.payment_transfer_accounts_attributes
  }
  return (
    <>
      <PageNavigation />
      <Container className="pt-12">
        <HeaderCreateForm
          headerParams={{ activeStep: 1, hint: 'Configura y agrega las preguntas de tu formulario.' }}
        />
        <FormsForm mutateForm={handleFormCreate} initialValues={initialValues} config={config} step={1} />
      </Container>
    </>
  )
}

export default FormsNew
