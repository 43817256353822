import React from 'react'
import PropTypes from 'prop-types'
import { getConfig } from '../../../../configs/formsConfig'
import GeneralConfiguration from './general/Configuration'
import QuestionsConfiguration from './general/QuestionsConfiguration'

const Configuration = ({ config: customConfig = {} }) => {
  const config = getConfig(customConfig)
  return <GeneralConfiguration config={config} />
}

const Questions = ({ config: customConfig = {} }) => {
  const config = getConfig(customConfig)

  return <QuestionsConfiguration config={config} />
}

const GeneralForm = {
  Configuration,
  Questions
}

export default GeneralForm

Configuration.propTypes = {
  config: PropTypes.object
}

Questions.propTypes = {
  config: PropTypes.object
}
