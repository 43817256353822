import ShortTextQuestion from '../components/organisms/question_types_citizens/ShortTextQuestion'
import ParagraphQuestion from '../components/organisms/question_types_citizens/ParagraphQuestion'
import DropdownQuestion from '../components/organisms/question_types_citizens/DropdownQuestion'
import RadioQuestion from '../components/organisms/question_types_citizens/RadioQuestion'
import CheckboxesQuestion from '../components/organisms/question_types_citizens/CheckboxesQuestion'
import DateQuestion from '../components/organisms/question_types_citizens/DateQuestion'
import EmailQuestion from '../components/organisms/question_types_citizens/EmailQuestion'
import PhoneQuestion from '../components/organisms/question_types_citizens/PhoneQuestion'
import DocTypeQuestion from '../components/organisms/question_types_citizens/DocTypeQuestion'
import UbigeoQuestion from '../components/organisms/question_types_citizens/UbigeoQuestion'
import HourQuestion from '../components/organisms/question_types_citizens/HourQuestion'
import FileQuestion from '../components/organisms/question_types_citizens/FileQuestion'
import SubtitleQuestion from '../components/organisms/question_types_citizens/SubtitleQuestion'
import QuestionType from '../components/organisms/question_types_citizens/QuestionType'
import CountryQuestion from '../components/organisms/question_types_citizens/CountryQuestion'
import ExternalInstitutionQuestion from '../components/organisms/question_types_citizens/ExternalInstitutionQuestion'
import InstitutionToReferQuestion from '../components/organisms/question_types_citizens/InstitutionToReferQuestion'
import BranchOfficeToReferQuestion from '../components/organisms/question_types_citizens/BranchOfficeToReferQuestion'
import EmailPhoneApplicantQuestion from '../components/organisms/question_types_citizens/EmailPhoneApplicantQuestion'
import FullNameQuestion from '../components/organisms/question_types_citizens/FullNameQuestion'
import AddressQuestion from '../components/organisms/question_types_citizens/AddressQuestion'
import CheckboxDoubleQuestion from '../components/organisms/question_types_citizens/CheckboxDoubleQuestion'
import RadioDoubleQuestion from '../components/organisms/question_types_citizens/RadioDoubleQuestion'
import AssessmentRadioQuestion from '../components/organisms/question_types_citizens/AssessmentRadioQuestion'
import AssessmentCheckboxesQuestion from '../components/organisms/question_types_citizens/AssessmentCheckboxesQuestion'
import AssessmentDropdownQuestion from '../components/organisms/question_types_citizens/AssessmentDropdownQuestion'

const availableQuestionTypes = {
  short_text: ShortTextQuestion,
  paragraph: ParagraphQuestion,
  dropdown: DropdownQuestion,
  radio: RadioQuestion,
  checkboxes: CheckboxesQuestion,
  checkboxes_double: CheckboxDoubleQuestion,
  radio_double: RadioDoubleQuestion,
  date: DateQuestion,
  email: EmailQuestion,
  phone: PhoneQuestion,
  doc_id_type: DocTypeQuestion,
  full_name: FullNameQuestion,
  ubigeo: UbigeoQuestion,
  address: AddressQuestion,
  hour: HourQuestion,
  file: FileQuestion,
  subtitle: SubtitleQuestion,
  country: CountryQuestion,
  external_institution: ExternalInstitutionQuestion,
  full_name_applicant: FullNameQuestion,
  email_phone_applicant: EmailPhoneApplicantQuestion,
  doc_id_type_applicant: DocTypeQuestion,
  refer_branch_office: BranchOfficeToReferQuestion,
  external_institution_task: InstitutionToReferQuestion,
  assessment_radio: AssessmentRadioQuestion,
  assessment_checkboxes: AssessmentCheckboxesQuestion,
  assessment_dropdown: AssessmentDropdownQuestion
}

const getQuestionTypeObject = kind => (kind ? { ...QuestionType, ...availableQuestionTypes[kind] } : null)

const getIndexSubtype = ({ questionsPreviousSteps = [], questions, index, parentQuestionIndex }) => {
  const subtype = getSubtype(questions[index].kind)
  const allQuestions = questionsPreviousSteps.concat(questions.slice(0, index + 1))
  const indexSubtype = allQuestions.reduce(
    (n, question) => n + (getSubtype(question.kind) === subtype && !question._destroy),
    0
  )
  return parentQuestionIndex ? `${parentQuestionIndex}.${indexSubtype}` : indexSubtype
}

const getSubtype = kind => getQuestionTypeObject(kind)?.subtype || 'Pregunta'

const getSelectedQuestionsBranch = (questions, question, answerLines) => {
  const answer = answerLines.find(a => a.question.id === question.id)
  const answeredAlternartiveId = question.payload.alternatives?.find(a => a.label === answer.answer)?.id
  return questions.filter(q => q.alternative_id === answeredAlternartiveId).filter(q => q.parent_id === question.id)
}

const getQuestionsPreviousSteps = (stepsValue, currentIndex) =>
  stepsValue.slice(0, currentIndex).reduce((questions, step) => questions.concat(step.questions_attributes || []), [])

export { getIndexSubtype, getSelectedQuestionsBranch, getQuestionTypeObject, getQuestionsPreviousSteps }
