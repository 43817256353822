import React from 'react'
import PropTypes from 'prop-types'
import SubType from '../../config_items/SubType'
import FormName from '../../config_items/FormName'
import ConfigurationContainer from '../../ConfigurationContainer'
import { getVisibleConfigItems } from '../../../../../configs/formTypeConfig'
import FormHint from '../../config_items/FormHint'
import CoverImage from '../../config_items/CoverImage'

const InformationGeneral = ({ config }) => {
  const configItems = [
    {
      visible: true,
      component: <SubType config={config} disabled />
    },
    {
      visible: true,
      component: (
        <FormName
          disabled={config.from_template}
          label="Título del formulario"
          hint="Escribe el título que tendrá tu formulario. Por ejemplo: Evaluación final de taller sobre uso de Facilita."
        />
      )
    },
    {
      visible: true,
      component: <CoverImage />
    },
    {
      visible: true,
      component: (
        <FormHint
          label="Indicación para llenar el formulario​"
          placeholder="¡Hola! Resuelve esta evaluación hasta el 9 de setiembre a las 6 p. m. El puntaje mínimo para pasar a la siguiente fase de selección es 12. Al finalizar, recibirás tu puntaje inmediatamente."
          hint="Escribe la indicación para que las personas completen el formulario. Incluye información importante, como fecha límite de registro de respuestas, horario de atención, qué pasos debe seguir, cuándo y cómo recibirá una respuesta, entre otros."
        />
      )
    }
  ]

  const visibleConfigItems = getVisibleConfigItems(configItems)

  return <ConfigurationContainer configItems={visibleConfigItems} title="Información general" />
}

export default InformationGeneral

InformationGeneral.propTypes = {
  config: PropTypes.object
}
