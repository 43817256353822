import PropTypes from 'prop-types'
import React from 'react'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckboxesCitizenField from '../question_fields/CheckboxesCitizenField'
import AssessmentCheckboxesField from '../question_config_fields/AssessmentCheckboxesField'

const Form = props => {
  return <AssessmentCheckboxesField baseName={props.baseName} />
}

const Template = props => {
  if (props.question.editable_alternative) {
    return <AssessmentCheckboxesField baseName={props.baseName} label={props.question.payload?.label} />
  } else {
    return <CheckboxesCitizenField payload={props.question.payload} name={props.name} disabled />
  }
}

const AssessmentCheckboxesQuestion = {
  selectOption: {
    value: 'assessment_checkboxes',
    label: 'Casilla (selección múltiple)',
    icon: <CheckBoxIcon fontSize="small" />
  },
  Form,
  Template,
  name: 'AssessmentCheckboxesQuestion'
}

export default AssessmentCheckboxesQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  baseName: PropTypes.string,
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}
