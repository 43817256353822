import React from 'react'
import PropTypes from 'prop-types'
import { getConfig } from '../../../../configs/formsConfig'
import GeneralConfiguration from './assessment/Configuration'
import QuestionsConfiguration from './assessment/QuestionsConfiguration'
import InformationGeneral from './assessment/InformationGeneral'
import { FormTypeContext } from '../../../../utils/contexts'

const Configuration = ({ config: customConfig = {} }) => {
  const config = getConfig(customConfig)
  return (
    <>
      <InformationGeneral config={config} />
      <GeneralConfiguration config={config} />
    </>
  )
}

const Questions = ({ config: customConfig = {} }) => {
  const config = getConfig(customConfig)

  return (
    <FormTypeContext.Provider value={'assessment'}>
      <QuestionsConfiguration config={config} />
    </FormTypeContext.Provider>
  )
}

const AssessmentForm = {
  Configuration,
  Questions
}

export default AssessmentForm

Configuration.propTypes = {
  config: PropTypes.object
}

Questions.propTypes = {
  config: PropTypes.object
}
