import React from 'react'
import PropTypes from 'prop-types'
import { HtmlField } from '../../../molecules/fields/HtmlField'
import * as validations from '../../../../utils/validations'

const AutoFeedback = ({ baseName }) => {
  return (
    <div className="md:w-10/12 lg:w-11/12 p-6 flex-row border mb-8">
      <p className="mb-2">Al finalizar la evaluación, se mostrará esta retroalimentación sobre la pregunta:</p>
      <HtmlField
        name={`${baseName}.payload.auto_feedback_text`}
        placeholder="Ej.: La respuesta correcta era la opción 2, ya que..."
        validate={validations.required()}
        margin="none"
        ariaLabel="Ingresa el feedback de la pregunta"
      />
    </div>
  )
}

export default AutoFeedback

AutoFeedback.propTypes = {
  baseName: PropTypes.string
}
